<template>
  <div class="redact-manuscript">
    <app-markdown ref="markdown" @submitSave="submitSave"></app-markdown>
    <app-notes-info ref="infoDrawer" @submitInfo="submitInfo" @saveInfo="saveInfo"></app-notes-info>
  </div>
</template>

<script>
import Markdown from '@/components/editor/MarkdownEditor'
import NotesInfoDrawer from '@/views/article/components/NotesInfoDrawer'
import { detailManuscript, saveManuscript, updateManuscript, writeNotes } from '@/api/ArticleApi'

export default {
  name: 'RedactManuscript',
  components: {
    'app-markdown': Markdown,
    'app-notes-info': NotesInfoDrawer
  },
  data() {
    return {
      serial: '',
      title: ''
    }
  },
  methods: {
    submitSave() {
      this.$refs.infoDrawer.showDrawer()
    },
    queryManuscriptContent(serial) {
      detailManuscript({
        serial: serial
      }).then((res) => {
        if (res.code === 200) {
          this.serial = res.data.serial
          this.$refs.markdown.fillContent(res.data.content)
          this.$refs.infoDrawer.fillTitle(res.data.title)
          document.title = res.data.title + ' - 凤凰集'
        }
      })
    },
    submitInfo(info) {
      const content = this.$refs.markdown.getContent()
      if (content.catalog === '') {
        this.$message({ message: '所属目录必填！', type: 'warning' })
        return
      }
      if (content.value === '') {
        this.$message({ message: '内容必填！', type: 'warning' })
        return
      }
      writeNotes({
        title: info.title,
        catalog: info.prefixCatalog,
        cover: info.cover,
        intro: info.intro,
        newCatalog: info.newCatalog,
        tags: info.tags,
        catalogJson: content.catalog,
        content: content.value
      }).then((res) => {
        if (res.code === 200) {
          this.$message({ message: '提交成功！', type: 'success' })
          this.$router.go(-1)
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    },
    saveInfo(title) {
      if (title === '') {
        this.$message({ message: '标题必填！', type: 'warning' })
        return
      }
      const content = this.$refs.markdown.getContent()
      if (content.value === '') {
        this.$message({ message: '内容必填！', type: 'warning' })
        return
      }
      this.saveOrUpdate(title, content.value, this.serial)
    },
    saveOrUpdate(title, content, serial) {
      if (serial === '') {
        saveManuscript({
          title: title,
          content: content
        }).then((res) => {
          if (res.code === 200) {
            this.serial = res.data
            this.$message({ message: '保存成功', type: 'success' })
            this.$refs.infoDrawer.closeDrawer()
          } else {
            this.$message({ message: res.msg, type: 'error' })
          }
        })
      } else {
        updateManuscript({
          serial: serial,
          title: title,
          content: content
        }).then((res) => {
          if (res.code === 200) {
            this.serial = res.data
            this.$message({ message: '保存成功', type: 'success' })
            this.$refs.infoDrawer.closeDrawer()
          } else {
            this.$message({ message: res.msg, type: 'error' })
          }
        })
      }
    }
  },
  mounted() {
    const serial = this.$route.query.serial
    if (serial) {
      this.queryManuscriptContent(serial)
    }
  }
}
</script>

<style scoped>
.redact-manuscript {
  width: 100%;
  height: 100%;
}
</style>
